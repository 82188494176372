import {useEffect, useState} from 'react';
import axios from 'axios';
import DestinationBox from './DestinationBox';
import SearchBox from './SearchBox';
import {backend} from '../config.js';
import {countryNames_de} from '../util';
import {Link} from 'react-router-dom';

const Home = () => {
  const [destdata, setDestdata] = useState([]);

  const shuffleArray = arr => {
    arr.sort(() => Math.random() - 0.5);
  };

  useEffect(() => {
    const advertiesedDestinations = [
      1, 2, 3, 4, 5, 7, 8, 9, 13, 14, 15, 16, 17, 18, 19, 24, 29, 36, 37, 42, 43, 47, 48, 50, 53, 55, 60, 61, 62, 64,
      68, 70, 72,
    ];
    axios
      .get(`${backend}/getdestinationbox?ids=${advertiesedDestinations.join('|')}`)
      .then(response => {
        let data = response.data.query;
        shuffleArray(data);
        setDestdata(data);
      })
      .catch(error => console.error(error));
  }, []);

  let countries = [];
  for (let c of Object.entries(countryNames_de)) {
    countries.push({
      value: c[0],
      label: (
        <>
          <img src={`${process.env.PUBLIC_URL}/images/flags/${c[0]}.png`} style={{width: 20}} alt={c[1]} />
          <div>{c[1]}</div>
        </>
      ),
    });
  }

  let all_destinations = [];
  for (let did of destdata) {
    all_destinations.push(<DestinationBox id={did.id} key={did.id} label={did.label_de} image={did.file_name} />);
  }

  let all_countries = [];
  for (let c of Object.entries(countryNames_de)) {
    all_countries.push(
      <Link to={`/country/${c[1]}/${c[0]}`} key={c[0]}>
        <div className="countryoption">
          <img src={`${process.env.PUBLIC_URL}/images/flags/${c[0]}.png`} style={{width: 20}} alt={c[1]} />
          <div>{c[1]}</div>
        </div>
      </Link>,
    );
  }

  return (
    <>
      <section className="topsection">
        <h1>moontrains</h1>
        <h2>Nachtzüge rund um die Welt</h2>
        <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Moontrains" className="logo" width="100" />
        <h3>Wohin willst du reisen?</h3>
        <SearchBox />
      </section>
      <section className="threecolumns_boxes">
        <div className="threecolumns_box">
          <a href="/tickets">Tickets</a> Finde schnell und einfach heraus, wo und wie du dein Ticket für deine Bahnreise
          kaufen kannst.
        </div>
        <div className="threecolumns_box">
          <a href="/search">Suche</a> Unsere Suche zeigt dir auf, wie du dein Reiseziel im Schlaf- oder Liegeabteil
          bequem über Nacht erreichen kannst.
        </div>
        <div className="threecolumns_box">
          <a href="/destinations">Reiseziele</a> Weisst du noch nicht, wohin verreisen? Schau inspirierende Reiseziele
          an, die du im Nachzug erreichen kannst.
        </div>
      </section>
      {/*<img src={`${process.env.PUBLIC_URL}/images/train2.jpg`} width="100%" alt="nachtzug" />*/}
      <section className="leftsection">
        <h3>Über Moontrains</h3>
        Moontrains bietet Informationen rund um Nachtzüge auf der ganzen Welt. Aktuell beinhaltet unsere interaktive
        Karte 183 Bahnlinien durch 42 Ländern auf 5 Kontinenten und laufend werden neue Verbindungen hinzugefügt. Zu
        allen Bahnstrecken können Fahrpläne und Anschlussstrecken abgerufen werden. Moontrains ist ein privates Projekt,
        keine Ticketverkaufsstelle. Wir zeigen dir, wie und wo du am besten Tickets und Bahnpässe für Schlafwagen,
        Liegewagen und Liegeabteile kaufst. Und mit der Routensuche kannst du herausfinden, wie du am einfachsten dein
        Reiseziel im Nachtzug erreichst.
      </section>
      <section className="leftsection">
        <div className="selection">
          <h3>Reiseländer</h3>
        </div>
        <div className="countrylist">{all_countries}</div>
      </section>
      <section className="leftsection destination_section">
        <h3>Reiseziele</h3>
        <div className="destinationboxes">{all_destinations}</div>
      </section>
    </>
  );
};

export default Home;
