import React from 'react';

const Footer = () => {
  return (
    <div id="footer">
      Moontrains: <a href="/contact.html">Kontakt & Datenschutz & Datenquellen</a>
      <br />
      <p className="remarks">
        Linienplan, Linienkarte, Fahrplan und Fahrplansuche für Nachtzüge in Europa, Amerika, Asien. Destinationen:
        Nachtzug nach Berlin, Amsterdam, Warschau, Wien, Ljubljana, Oslo, Stockholm, Rom, Venedig. Tickets für
        Schlafwagen, Liegewagen und Liegeabteil.
      </p>
    </div>
  );
};

export default Footer;
