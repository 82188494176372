import {Style, Stroke, Fill, Circle, Text} from 'ol/style';

const getThickness = resolution => {
  const zoom = Math.ceil((Math.log(resolution) - Math.log(156543.03390625)) / Math.log(0.5));
  let thickness;
  if (zoom < 5) {
    thickness = 1;
  } else if (zoom < 6) {
    thickness = 2;
  } else {
    thickness = 3;
  }
  return thickness;
};

const routeStyle = (feature, resolution) => {
  return new Style({
    stroke: new Stroke({
      color: '#e53d89',
      width: getThickness(resolution),
    }),
  });
};

const routeStyleDeactiv = (feature, resolution) => {
  return new Style({
    stroke: new Stroke({
      color: '#FFE2DE',
      width: getThickness(resolution),
    }),
  });
};

const routeStyleActive = (feature, resolution) => {
  return new Style({
    stroke: new Stroke({
      color: '#e53d89',
      width: getThickness(resolution) + 2,
    }),
    zIndex: 999,
  });
};

const routeStyleActive2nd = (feature, resolution) => {
  return new Style({
    stroke: new Stroke({
      color: '#EC61A0',
      width: getThickness(resolution),
    }),
    zIndex: 998,
  });
};

const getTextSize = (level, zoom) => {
  if (zoom === 5) {
    return '12px Calibri,sans-serif';
  }
  if (zoom === 6) {
    if (level === 5) {
      return '17px Calibri,sans-serif';
    }
    return '12px Calibri,sans-serif';
  }
  if (zoom === 7) {
    if (level === 5) {
      return '22px Calibri,sans-serif';
    }
    return '14px Calibri,sans-serif';
  }
};

const stopStyle = (feature, resolution) => {
  const zoom = Math.ceil((Math.log(resolution) - Math.log(156543.03390625)) / Math.log(0.5));
  if (zoom <= 4) {
    //no feature at zoom 4 or smaller
    return new Style();
  }
  if (zoom === 5 && feature.get('level') === 5) {
    //only level 5 cities at zoom 5
    return new Style({
      text: new Text({
        font: getTextSize(5, 5),
        text: feature.get('stop_label'),
        textBaseline: 'middle',
        fill: new Fill({
          color: '#000',
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3,
        }),
      }),
    });
  } else if (zoom === 6 && feature.get('level') >= 4) {
    let font = getTextSize(feature.get('level'), 6);
    return new Style({
      image: new Circle({
        radius: 4,
        stroke: new Stroke({
          color: '#c0c0c0',
          width: 2,
        }),
        fill: new Fill({color: '#FFFFFF'}),
      }),
      text: new Text({
        font: font,
        text: feature.get('stop_label'),
        textBaseline: 'bottom',
        fill: new Fill({
          color: '#000',
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3,
        }),
      }),
    });
  } else if (zoom === 7 && feature.get('level') > 0) {
    //zoom=7
    if (feature.get('level') <= 3) {
      return new Style({
        image: new Circle({
          radius: 4,
          stroke: new Stroke({
            color: '#c0c0c0',
            width: 2,
          }),
          fill: new Fill({color: '#FFFFFF'}),
        }),
      });
    }
    return new Style({
      image: new Circle({
        radius: 4,
        stroke: new Stroke({
          color: '#c0c0c0',
          width: 2,
        }),
        fill: new Fill({color: '#FFFFFF'}),
      }),
      text: new Text({
        font: getTextSize(feature.get('level'), 7),
        text: feature.get('stop_label'),
        textBaseline: 'bottom',
        fill: new Fill({
          color: '#000000',
        }),
        stroke: new Stroke({
          color: '#FFFFFF',
          width: 3,
        }),
      }),
    });
  } else {
    return new Style({});
  }
};

const stopStyleActive = (feature, resolution) => {
  const zoom = Math.ceil((Math.log(resolution) - Math.log(156543.03390625)) / Math.log(0.5));
  return new Style({
    image: new Circle({
      radius: 4,
      stroke: new Stroke({
        color: '#C20058',
        width: 2,
      }),
      fill: new Fill({color: '#FFFFFF'}),
    }),
    text: new Text({
      font: getTextSize(feature.get('level'), zoom),
      text: feature.get('stop_label'),
      textBaseline: 'bottom',
      fill: new Fill({
        color: '#C20058',
      }),
      stroke: new Stroke({
        color: '#FFFFFF',
        width: 3,
      }),
    }),
  });
};

const backgroundStyle = () => {
  return new Style({
    fill: new Fill({
      color: '#F8F8F8',
    }),
    stroke: new Stroke({
      color: 'rgba(100, 100, 100, 0.2)',
      width: 1,
    }),
  });
};

export {
  routeStyle,
  routeStyleDeactiv,
  routeStyleActive,
  routeStyleActive2nd,
  stopStyle,
  stopStyleActive,
  backgroundStyle,
};
