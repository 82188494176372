import React, {useState, useRef, useEffect} from 'react';
import {Link, Navigate, useLocation} from 'react-router-dom';

import Map from 'ol/Map';
import Zoom from 'ol/control/Zoom';
import View from 'ol/View';
import GeoJSON from 'ol/format/GeoJSON';
import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {defaults} from 'ol/interaction';
import {createEmpty, extend} from 'ol/extent';
import {
  routeStyle,
  routeStyleDeactiv,
  routeStyleActive,
  routeStyleActive2nd,
  stopStyle,
  stopStyleActive,
  backgroundStyle,
} from '../mapStyles';

const Trainmap = () => {
  const [showRoute, setShowRoute] = useState(undefined);
  const [showStop, setShowStop] = useState(undefined);
  const [renderedCompleted, setRenderedCompleted] = useState(false);
  const highlightedFeatures = useRef([]);
  const hoverFeatures = useRef([]);
  const routesLayer = useRef();
  const stopsLayer = useRef();
  const sourceRoutes = useRef();
  const sourceStops = useRef();
  const map = useRef();
  const mapContainer = useRef();
  const {pathname} = useLocation();

  useEffect(() => {
    const target = mapContainer.current;
    const backgroundLayer = new VectorLayer({
      source: new VectorSource({
        url: process.env.PUBLIC_URL + '/geojson/countries.json',
        format: new GeoJSON(),
      }),
      style: backgroundStyle,
      name: 'background',
      updateWhileInteracting: true,
      updateWhileAnimating: true,
    });

    sourceStops.current = new VectorSource({
      url: process.env.PUBLIC_URL + '/geojson/stops.json',
      format: new GeoJSON(),
    });

    stopsLayer.current = new VectorLayer({
      source: sourceStops.current,
      style: stopStyle,
      name: 'stops',
      updateWhileInteracting: true,
      updateWhileAnimating: true,
    });

    sourceRoutes.current = new VectorSource({
      url: process.env.PUBLIC_URL + '/geojson/routes.json',
      format: new GeoJSON(),
    });

    routesLayer.current = new VectorLayer({
      source: sourceRoutes.current,
      style: routeStyle,
      name: 'routes',
      updateWhileInteracting: true,
      updateWhileAnimating: true,
    });

    map.current = new Map({
      controls: [new Zoom()],
      layers: [backgroundLayer, routesLayer.current, stopsLayer.current],
      target: target,
      view: new View({
        center: [2000000, 6000000],
        zoom: 5,
        maxZoom: 7,
      }),
      interactions: defaults({mouseWheelZoom: false}),
    });

    map.current.on('click', handleMapClick);
    map.current.on('pointermove', handlePointerMove);
    map.current.on('rendercomplete', () => setRenderedCompleted(true));

    return () => {
      if (target) {
        target.removeChild(target.children[0]);
      }
    };
  }, []);

  //run after initial render
  useEffect(() => {
    if (pathname.includes('/route/') && renderedCompleted) {
      //reset style
      highlightedFeatures.current.forEach(f => f.setStyle(null));
      highlightedFeatures.current = [];
      routesLayer.current.setStyle(routeStyleDeactiv);
      //get new active routes
      const routeIds = pathname
        .split('/')[2]
        .split('|')
        .map(x => parseInt(x));
      const routeId = parseInt(pathname.split('/')[3]);
      let extent = createEmpty();
      for (let f of sourceRoutes.current.getFeatures()) {
        if (f.get('routeids').some(item => routeIds.includes(item))) {
          highlightedFeatures.current.push(f);
          f.setStyle(routeStyleActive2nd);
        }
        if (f.get('routeids').includes(routeId)) {
          highlightedFeatures.current.push(f);
          f.setStyle(routeStyleActive);
          extend(extent, f.getGeometry().getExtent());
        }
      }
      if (extent[0] !== Infinity) {
        const view = map.current.getView();
        view.fit(extent, {
          padding: [50, 50, 50, 50],
          maxZoom: 7,
          duration: 200,
        });
      }
    } else if (pathname.includes('/stop/') || (pathname.includes('/destination/') && renderedCompleted)) {
      //reset style
      highlightedFeatures.current.forEach(f => f.setStyle(null));
      highlightedFeatures.current = [];
      //get new active stop
      const stopId = parseInt(pathname.split('/')[3]);
      for (let f of sourceStops.current.getFeatures()) {
        if (f.get('stop_id') === stopId) {
          highlightedFeatures.current.push(f);
          f.setStyle(stopStyleActive);
        }
      }
    }
  }, [pathname, renderedCompleted]);

  useEffect(() => {
    //reset after one render
    setShowRoute(undefined);
  }, [showRoute]);

  useEffect(() => {
    //reset after one render
    setShowStop(undefined);
  }, [showStop]);

  const handleMapClick = e => {
    // check if a route was not hit
    const hit = map.current.hasFeatureAtPixel(e.pixel, {
      hitTolerance: 2,
      layerFilter: la => {
        return la.get('name') === 'routes' || la.get('name') === 'stops';
      },
    });
    if (!hit) {
      //reset style if clicked on the background
      highlightedFeatures.current.forEach(f => f.setStyle(null));
      highlightedFeatures.current = [];
      routesLayer.current.setStyle(routeStyle);
      stopsLayer.current.setStyle(stopStyle);
    }

    let routeIds = [];
    let stop = undefined;
    map.current.forEachFeatureAtPixel(
      e.pixel,
      f => {
        const routeIds_at_feature = f.get('routeids');
        if (routeIds_at_feature) {
          routeIds = [...new Set([...routeIds, ...routeIds_at_feature])];
        }
        const stopId_at_feature = f.get('stop_id');
        if (stopId_at_feature) {
          stop = {id: stopId_at_feature, label: f.get('stop_label')};
        }
      },
      {
        hitTolerance: 2,
      },
    );
    if (stop) {
      setShowStop(stop);
      //const element = document.getElementById('content');
      //element.scrollIntoView({behavior: 'smooth'});
    } else if (routeIds.length > 0) {
      setShowRoute(routeIds);
      //const element = document.getElementById('content');
      //element.scrollIntoView({behavior: 'smooth'});
    } else {
      const element = document.getElementById('map');
      element.scrollIntoView({behavior: 'smooth'});
    }
  };

  const handlePointerMove = e => {
    const hit = map.current.hasFeatureAtPixel(e.pixel, {
      hitTolerance: 2,
      layerFilter: la => {
        return la.get('name') === 'routes' || la.get('name') === 'stops';
      },
    });
    if (hit) {
      map.current.getTargetElement().style.cursor = 'pointer';
    } else {
      map.current.getTargetElement().style.cursor = '';
    }

    if (hoverFeatures.current) {
      hoverFeatures.current.forEach(f => f.setStyle(null));
      hoverFeatures.current = [];
    }
    if (map.current.getView().getZoom() > 5) {
      map.current.forEachFeatureAtPixel(
        e.pixel,
        f => {
          if (f.get('stop_id')) {
            f.setStyle(stopStyleActive);
            hoverFeatures.current.push(f);
          }
        },
        {
          hitTolerance: 1,
        },
      );
    }
  };

  return (
    <>
      <div ref={mapContainer} id="map"></div>
      <div id="logo">
        <Link to="/">
          <img src={process.env.PUBLIC_URL + '/images/logo_with_text.png'} alt="moon trains" width="100" height="141" />
        </Link>
      </div>
      {showRoute ? <Navigate to={`/route/${showRoute.join('|')}/${showRoute[0]}`} /> : ''}
      {showStop ? (
        showStop.id.toString().includes('d') ? (
          <Navigate to={`/destination/${showStop.label}/${showStop.id}`} />
        ) : (
          <Navigate to={`/stop/${showStop.label}/${showStop.id}`} />
        )
      ) : (
        ''
      )}
    </>
  );
};

export default Trainmap;
